const stickyMenuHelper = () => {
  const navigateEl = document.querySelector('[data-navigation]')
  const navigateElOffSetTop = navigateEl.offsetTop

  const eventHandler = event => stickToTop(event, navigateEl, navigateElOffSetTop)

  const stickToTop = (event, navigateEl) => {
    // if window offset (which changes) 
    // is higher than el top (which doesnt change because the el stays on the same place in the page, its the page scroll that changes) 
    // then do setFixed
    // if not
    // do unSetFixed
    window.scrollY  > navigateEl.offsetTop
      ? setFixed(navigateEl)
      : unSetFixed(navigateEl)
  }

  // add class and condiitonal for removing class
  const setFixed = (navigateEl) => {
    navigateEl.classList.add("navigation--fixed")

    if (window.scrollY < navigateElOffSetTop) {
      navigateEl.classList.remove("navigation--fixed")
    }
  }

  // remove class
  const unSetFixed = (navigateEl) => {
    navigateEl.classList.remove("navigation--fixed")
  }
  
  // add event listener with callback to function
  window.addEventListener('scroll', eventHandler)
}

export default stickyMenuHelper()