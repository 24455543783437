// helper for table height
//
// sets height of cells
//
// Created by Matthias Poortvliet @ Lennoc Development 24/10/2021
const tableHelper = () => {
  const props = {
    tableSelector: '[data-table]',
    tableColumnSelector: '[data-table-column]',
    tableCellSelector: '[data-table-cell]',
    rowHeight: []
  }
  // for every table on the page
  const tables = document.querySelectorAll(props.tableSelector)
  for (const table of tables) {
    // create an array of cell heights per row derived from columns
    const columns = Array.from(table.querySelectorAll(props.tableColumnSelector))
    columns.map((column, columnIndex) => {
    const cells = Array.from(column.querySelectorAll(props.tableCellSelector))
      cells.map((cell, cellIndex) => {
        if (!props.rowHeight[cellIndex]) {
          props.rowHeight[cellIndex] = []
        }
        props.rowHeight[cellIndex][columnIndex] = cell.clientHeight
      })
    })
    // set highest cell height of row to every cell per index/row derived from columns
    for (const column of columns) {
      const cells = Array.from(column.querySelectorAll(props.tableCellSelector))
      cells.map((cell, index) => {
        const highest = Math.max(...props.rowHeight[index])
        cell.style.height = highest + 'px'
      })
    }
  }
}

export default tableHelper()