// collapsible helper v1.1
//
// handles class switching for opening and closing collapsible content
//
// both the trigger and the content need be in the same scope
//
// 12/12/2019 - Update: Exact height of element from .scrollHeight instead of a max-height class
//
// Created by Matthias Poortvliet @ Lennoc Development 20/08/2019
const collapsibelHelper = () => {
  const triggerSelector = '[data-collapsible="trigger"]'
  const contentSelector = '[data-collapsible="content"]'
  const parentSelelector = 'body'
  const triggeredClass = 'collapsible--triggered'

  const triggerElements = document.querySelectorAll(triggerSelector)

  for(const triggerElement of triggerElements) {
    triggerElement.addEventListener('click', (e) => {
      handleCollapse(e)
    })

    triggerElement.addEventListener('touch', (e) => {
      handleCollapse(e)
    })
  }

  const handleCollapse = (e) => {
    const parent = e.srcElement.parentElement
    const content = parent.querySelector(contentSelector)



    if (parent.classList.contains(triggeredClass)) {
      content.style.height = 0
      parent.classList.remove(triggeredClass)
      // Once the
      window.addEventListener('resize', (e, element = content) => {
        if (window.innerWidth >= 860 ) {
          element.style.setProperty('height', 'initial');
        }
      });
    } else {
      const list = parent.closest(parentSelelector)
      const parentToClose = list.querySelector('.' + triggeredClass)
      if (parentToClose) {
        const contentToClose = parentToClose.querySelector(contentSelector)
        parentToClose.classList.remove(triggeredClass)
        contentToClose.style.height = 0
      }
      parent.classList.add(triggeredClass)
      const height = content.scrollHeight
      content.style.height = height + 'px'
    }
  }
}

export default collapsibelHelper()